import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// Dil dosyalarını içe aktarın
import enTranslation from './locales/en.json'
import trTranslation from './locales/tr.json'

i18n
  .use(initReactI18next) // React binding ile i18next'i başlatın
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      tr: {
        translation: trTranslation,
      },
    },
    lng: 'tr', // Varsayılan dil
    fallbackLng: 'tr', // Bir çeviri bulunamadığında kullanılacak dil

    interpolation: {
      escapeValue: false,
    },
  })

export default i18n

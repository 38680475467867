import React, { useState } from 'react'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { User } from '../../../types/user'
import { useAppDispatch } from '../../../redux/hooks'
import AuthService from '../../../api/Auth/Auth.Index'
import { setUser } from '../../../redux/slices/userSlice'

import Preloader from '../../Base/Preloader'

const Login: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const fromPath = queryParams.get('from')
  // const fromPath = location.state?.from

  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState<boolean>(false)
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [error, setError] = useState<boolean>(false)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true)
    e.preventDefault()
    const req = {
      username,
      password,
    }
    try {
      const data = await AuthService.postLogin(req)
      await localStorage.setItem('user', JSON.stringify(data))
      const getMeData = await AuthService.getMe()
      const userInfo: User = getMeData
      dispatch(setUser(userInfo))
      if (fromPath) {
        navigate(fromPath)
      } else {
        navigate('/')
      }
      setError(false)
    } catch (err) {
      console.log('login error =>', err)
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      <div className="login-page w-50 mx-auto mt-10 p-3">
        <div className="row">
          <h2 className="text-center mb-2">{t('loginTitle')}</h2>
          <p className="text-center mb-4 fs-14">
            {t('login.account.status.text')}
            &nbsp;
            <Link to="/register">{t('login.register')}</Link>
          </p>
          <form onSubmit={handleSubmit} className="">
            <div className="input-container">
              <input
                type="text"
                id="username"
                className="fs-14"
                placeholder=" "
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <label htmlFor="username" className="fs-14">
                {t('username')}&nbsp;{t('or')}&nbsp;{t('phone.number.text')}
              </label>
            </div>
            <div className="input-container">
              <input
                type="password"
                id="password"
                placeholder=" "
                className="fs-14"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <label htmlFor="password" className="fs-14">
                {t('password')}:
              </label>
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-block position-relative"
              disabled={loading}
              style={loading ? { height: '44px' } : {}}
            >
              {loading ? <Preloader loading={loading} /> : t('login')}
            </button>
            <p className="text-center fs-14 mt-3 mb-3">
              {t('password.forgot.text')}
              &nbsp;
              <Link to="/forgot-password">{t('password.forgot.link')}</Link>
            </p>
          </form>
          {error && (
            <p className="text-danger text-center mt-3">
              {t('login.error.message')}
            </p>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Login

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ProductState {
  products: Array<{ id: number; name: string; price: number }>
}

const initialState: ProductState = {
  products: [],
}

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProducts: (
      state,
      action: PayloadAction<Array<{ id: number; name: string; price: number }>>,
    ) => {
      state.products = action.payload
    },
  },
})

export const { setProducts } = productSlice.actions

export default productSlice.reducer

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import AuthService from '../../../api/Auth/Auth.Index'
import { User } from '../../../types/user'
import { RootState } from '../../../redux/store'
import { setUser, resetUser } from '../../../redux/slices/userSlice'
import { useAppDispatch } from '../../../redux/hooks'

const Profile: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const currentPath = window.location.pathname

  const [loading, setLoading] = useState<boolean>(true)
  const [isLogin, setLogin] = useState<boolean>(false)
  const [isData, setData] = useState<User | null>(null)

  const user = useSelector((state: RootState) => state.user.user)

  const getProfile = async () => {
    try {
      setLoading(true)
      const response = await AuthService.getMe()
      if (response) {
        const userInfo: User = response
        dispatch(setUser(userInfo))
        setLogin(true)
        setData(userInfo)
      }
    } catch (err) {
      console.log(err)
      navigate(`/login?from=${encodeURIComponent(currentPath)}`)
    } finally {
      setLoading(false)
    }
  }

  const formatDateToDDMMYYYY = (date: string): string => {
    const parsedDate = new Date(date)
    const day = String(parsedDate.getDate()).padStart(2, '0')
    const month = String(parsedDate.getMonth() + 1).padStart(2, '0')
    const year = parsedDate.getFullYear()
    return `${day}-${month}-${year}`
  }

  const formattedBirthDay = user?.birthDay
    ? formatDateToDDMMYYYY(user?.birthDay)
    : ''

  useEffect(() => {
    if (!user) {
      getProfile()
    } else {
      console.log('Profile.tsx user line.48 => ', user)
      setData(user)
      setLogin(true)
      setLoading(false)
    }
  }, [dispatch])

  const logout = () => {
    localStorage.removeItem('user')
    navigate('/')
    dispatch(resetUser())
  }

  if (loading) {
    return <div>{t('loading')}</div>
  }

  if (!isLogin) {
    return <p>{t('login.text.message')}</p>
  }

  if (isLogin && isData) {
    return (
      <div className="container">
        <div className="row">
          <div className="input-container">
            <label htmlFor="username" className="fs-14">
              {t('name')}
            </label>
            <input
              type="text"
              id="name"
              className="fs-14"
              placeholder=" "
              value={isData?.name ?? ''}
              disabled
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="surname" className="fs-14">
              {t('surname')}
            </label>
            <input
              type="text"
              id="surname"
              className="fs-14"
              placeholder=" "
              value={isData.surname ?? ''}
              disabled
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="username" className="fs-14">
              {t('username')}
            </label>
            <input
              type="text"
              id="username"
              className="fs-14"
              placeholder=" "
              value={isData.username ?? ''}
              disabled
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="email" className="fs-14">
              {t('email')}
            </label>
            <input
              type="text"
              id="email"
              className="fs-14"
              placeholder=" "
              value={isData.email ?? ''}
              disabled
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="phone" className="fs-14">
              {t('phone.number.text')}
            </label>
            <input
              type="text"
              id="phone"
              className="fs-14"
              placeholder=" "
              value={isData.phone ?? ''}
              disabled
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="tckn" className="fs-14">
              {t('tckn')}
            </label>
            <input
              type="text"
              id="tckn"
              className="fs-14"
              placeholder=" "
              value={isData.tckn ?? ''}
              disabled
              required
            />
          </div>
          {isData.birthDay && (
            <div className="input-container">
              <label htmlFor="birthDay" className="fs-14">
                {t('birthDay')}
              </label>
              <input
                type="text"
                id="birthDay"
                className="fs-14"
                placeholder=" "
                value={formattedBirthDay}
                disabled
                required
              />
            </div>
          )}
          <div className="logout-button">
            <button className="logout" onClick={logout}>
              {t('logout')}
            </button>
          </div>
        </div>
      </div>
    )
  }

  return <div></div>
}

export default Profile

import React from 'react'
import loadingGif from '../../images/preloader.gif'

interface PreloaderProps {
  loading: boolean
}

const Preloader: React.FC<PreloaderProps> = ({ loading }) => {
  if (!loading) return null

  return (
    <div style={{ position: 'relative' }}>
      <img
        src={loadingGif}
        alt="Loading..."
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '24px',
          height: '24px',
        }}
      />
    </div>
  )
}

export default Preloader

import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAppSelector, useAppDispatch } from '../../../redux/hooks'
// import { useSelector } from '../../../redux/store'
// import AuthService from '../../../api/Auth/Auth.Index'
// import { getUsers } from '../../../redux/reducer/users'

const Home: React.FC = () => {
  const [isLogin, setLogin] = useState(false)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const username = useAppSelector((state) => state.user.loggedIn)

  const setToLogin = () => {
    navigate('/login') // Login olduktan sonra yönlendirilecek sayfa
  }

  useEffect(() => {
    console.log(111, username)
    if (username) {
      setLogin(true)
    }
  }, [dispatch])

  // async function getMe() {
  //   try {
  //     const getMeData = await AuthService.getMe()
  //     dispatch(getUsers(getMeData))
  //   } catch (error) {
  //     console.log('getMe error => ', error)
  //     navigate('/login')
  //   }
  // }

  return (
    <div>
      <h1>Home</h1>
      <p>Welcome to the home page!</p>
      {isLogin ? (
        <p>You are logged in!</p>
      ) : (
        <button onClick={setToLogin}>Login</button>
      )}
    </div>
  )
}

export default Home

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { User } from '../../types/user'

interface UserState {
  user: User | null
  loggedIn: boolean
}

const initialState: UserState = {
  user: null,
  loggedIn: false,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload
      state.loggedIn = true
    },
    logout: (state) => {
      state.user = null
      state.loggedIn = false
    },
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload
    },
    resetUser(state) {
      state.user = null
    },
  },
})

export const { setUser, logout, setLoggedIn, resetUser } = userSlice.actions

export default userSlice.reducer

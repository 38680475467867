import axios from 'axios'

const baseURL = process.env.REACT_APP_BASE_URL

const API = axios.create({
  baseURL: baseURL,
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' },
})

async function getToken() {
  const value = await localStorage.getItem('user')
  // console.log('getToken value => ', value)
  if (value) {
    const userToken = JSON.parse(value)
    return userToken.accessToken
  } else {
    return null
  }
}

API.interceptors.request.use(
  async (request) => {
    if (request.headers && request.headers.skipInterceptor) {
      // `skipInterceptor` flag'ini kaldır
      delete request.headers.skipInterceptor
      return request
    }
    const token = await getToken()
    // console.log('API request interceptor token =>', token)
    if (token) {
      request.headers.Accept = 'application/json'
      request.headers.Authorization = token
    }
    return request
  },
  (error) => {
    Promise.reject(error)
  },
)

API.interceptors.response.use(
  (response) => {
    // console.log('API response interceptor =>', response)
    if (response.config.url) {
      response.config.url.replace(response.config.url, '')
    }
    return response
  },
  async (error) => {
    // console.log('API response interceptor =>', error)
    if (!error.response) {
      return Promise.reject(error)
    }
    if (error.response && error.response.data) {
      if (error.response.status === 401) {
        localStorage.removeItem('user')
      }
      return Promise.reject(error.response)
    }
    return Promise.reject(error)
  },
)

export default API

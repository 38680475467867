import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './redux/store'

import Header from './components/Layout/Header'
import Login from './components/Pages/Login/Login.Index'
import Register from './components/Pages/Register/Register.Index'
import Home from './components/Pages/Home/Home.Index'
import Profile from './components/Pages/Profile/Profile.Index'

import 'bootstrap/scss/bootstrap.scss'
import './styles/main.scss'

const NoPage = () => <div>No Page</div>

const App: React.FC = () => (
  <Provider store={store}>
    <Router>
      <Header />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </Router>
  </Provider>
)

export default App

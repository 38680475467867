import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import '../../styles/Header.scss'

import AuthService from '../../api/Auth/Auth.Index'
import { useAppSelector } from '../../redux/hooks'
import { RootState } from '../../redux/store'
import { setUser, setLoggedIn } from '../../redux/slices/userSlice'

import { FaLock } from 'react-icons/fa'
import { CgProfile } from 'react-icons/cg'
import { MdLanguage } from 'react-icons/md'

import { ReactComponent as Logo } from '../../images/logo-white.svg' // 1589 x 545
import SearchComponent from './SearchComponent'

const Header: React.FC = () => {
  const [isLogin, setLogin] = useState<boolean>(false)
  const { t, i18n } = useTranslation()

  const dispatch = useDispatch()

  const userState = useSelector((state: RootState) => state.user.user)
  const username = useAppSelector((state) => state.user.user)
  const isLoginStatus = useAppSelector((state) => state.user.loggedIn)

  const getMe = async () => {
    try {
      const getMeData = await AuthService.getMe()
      dispatch(setUser(getMeData))
      dispatch(setLoggedIn(true))
      setLogin(true)
      console.log('Header getMeData ', getMeData)
      console.log('Header isLoginStatus ', isLoginStatus)
    } catch (error) {
      console.log('Header getMe error => ', error)
    }
  }

  useEffect(() => {
    const user = localStorage.getItem('user')
    if (user) {
      const userInfo = JSON.parse(user)
      console.log(113 + ' localstorage => ', userInfo)
      getMe()
      dispatch(setLoggedIn(true))
      setLogin(true)
    }
  }, [dispatch])

  const changeLanguage = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    lng: string,
  ) => {
    event.preventDefault()
    i18n.changeLanguage(lng)
  }

  const handleSearch = (query: string) => {
    console.log('Search query:', query)
    // Arama sonuçlarını işleme kodu buraya gelecek
  }

  console.log('Header.tsx - user => ', userState)

  return (
    <>
      <div className="header-body">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="hBody-content d-flex justify-content-between align-items-center">
                <div className="logo-content">
                  <Link to="/">
                    <Logo style={{ width: '250px', height: '85px' }} />
                  </Link>
                </div>
                <div className="search-content">
                  <SearchComponent onSearch={handleSearch} />
                </div>
                <div className="link-content">
                  <Link
                    to="/"
                    onClick={(event) =>
                      changeLanguage(
                        event,
                        i18n.language === 'en' ? 'tr' : 'en',
                      )
                    }
                  >
                    <MdLanguage color="white" size={14} />
                    {i18n.language === 'en' ? t('Turkish') : t('English')}
                  </Link>
                  {isLogin ? (
                    <>
                      <Link to="/basket">{t('basket')}</Link>
                      <Link to="/profile">
                        <CgProfile color="white" size={14} />
                        {username?.username}
                      </Link>
                    </>
                  ) : (
                    <Link to="/login">
                      <FaLock color="white" size={14} />
                      {t('login')}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header

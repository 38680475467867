import API from '../../http/Api'

interface LoginData {
  username: string
  password: string
}

interface RegisterData {
  name: string
  surname: string
  username: string
  password: string
  phone: number | undefined
  tckn: number | undefined
  birthDay: Date | null
}

const AuthService = {
  postLogin: async (data: LoginData) => {
    try {
      console.log('postLogin data => ', data)
      const response = await API.post('/auth/login', data, {
        headers: {
          skipInterceptor: true,
        },
      })
      console.log('postLogin response => ', response)
      if (response.status === 200) {
        return response.data
      }
      return response
    } catch (error) {
      console.log('Error creating post:', error)
      throw error
    }
  },

  postRegister: async (data: RegisterData) => {
    try {
      console.log('postRegister data => ', data)
      const response = await API.post('/auth/register', data, {
        headers: {
          skipInterceptor: true,
        },
      })
      console.log('postRegister response => ', response)
      if (response.status === 201) {
        return response
      }
      return response
    } catch (error) {
      console.log('Error postRegister: ', error)
      throw error
    }
  },

  getMe: async () => {
    try {
      const response = await API.get('/profile/me')
      console.log('getMe response => ', response)
      return response.data
    } catch (error) {
      console.log('Error fetching getMe:', error)
      throw error
    }
  },

  getAddress: async () => {},
}

export default AuthService

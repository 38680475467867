import React, { useState, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { FaSearch } from 'react-icons/fa'

interface SearchComponentProps {
  onSearch: (query: string) => void
}

const SearchComponent: React.FC<SearchComponentProps> = ({ onSearch }) => {
  const { t } = useTranslation()
  const [query, setQuery] = useState<string>('')

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }

  const handleSearch = () => {
    onSearch(query)
  }

  return (
    <div className="input-group mb-3">
      <input
        type="text"
        className="form-control roboto-light"
        placeholder={t('search.placeholder')}
        value={query}
        onChange={handleInputChange}
      />
      <button
        className="btn btn-outline-secondary"
        type="button"
        onClick={handleSearch}
      >
        <FaSearch className="search-icon" />
      </button>
    </div>
  )
}

export default SearchComponent

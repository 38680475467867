import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import { useNavigate, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// import { User } from '../../../types/user'
// import { useAppDispatch } from '../../../redux/hooks'
import AuthService from '../../../api/Auth/Auth.Index'
// import { setUser } from '../../../redux/slices/userSlice'

const Register: React.FC = () => {
  const { t } = useTranslation()
  // const navigate = useNavigate()

  // const dispatch = useAppDispatch()

  const [registered, setRegistered] = useState<boolean>(false)
  const [username, setUsername] = useState<string>('')
  const [phone, setPhone] = useState<number>()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [tckn, setTckn] = useState<number>()
  const [name, setName] = useState<string>('')
  const [surname, setSurname] = useState<string>('')
  const [birthDay, setBirthDay] = useState<Date | null>(null)
  const [error, setError] = useState<string>('')
  const [success, setSuccess] = useState<string>('')

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const req = {
      name,
      email,
      surname,
      username,
      password,
      tckn,
      phone,
      birthDay,
    }
    try {
      console.log(10, req)
      const data = await AuthService.postRegister(req)
      // await localStorage.setItem('user', JSON.stringify(data))
      // const getMeData = await AuthService.getMe()
      // const userInfo: User = getMeData
      // dispatch(login())
      console.log(11, data)
      if (data.status === 201) {
        setRegistered(true)
      }
      // console.log(12, getMeData)
      setSuccess('success')
      // // dispatch(setUser(userInfo))
      // navigate('/')
    } catch (err) {
      console.log('login error =>', err)
      setError('Login failed. Please check your credentials and try again.')
    }
  }

  const handlePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10)
    setPhone(newValue)
  }

  const handleTckn = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10)
    setTckn(newValue)
  }

  const handleBirthDay = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(event.target.value) // Input değerini Date türüne dönüştür
    setBirthDay(newDate)
  }

  if (registered) {
    return (
      <div className="w-50 mx-auto mt-10 p-3 pb-5">
        <p className="text-center mb-4 fs-16">
          {t('register.success.text', { email })}
        </p>
        <p className="text-center mb-4 fs-12">
          {t('register.success.warning')}
        </p>
      </div>
    )
  }

  // username, password, tckn, name, surname, email, phone, birthDay, is_admin, photo
  return (
    <React.Fragment>
      <div className="register-page w-50 mx-auto mt-10 p-3 pb-5">
        <div className="row">
          <h2 className="text-center mb-2">{t('login.register')}</h2>
          <p className="text-center mb-4 fs-14">
            {t('login.account.status.text.isThere')}
            &nbsp;
            <Link to="/login">{t('login')}</Link>
          </p>
          <form onSubmit={handleSubmit} className="">
            <div className="input-container">
              <input
                type="text"
                id="name"
                className="fs-14"
                placeholder=" "
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <label htmlFor="username" className="fs-14">
                {t('name')}
              </label>
            </div>
            <div className="input-container">
              <input
                type="text"
                id="surname"
                className="fs-14"
                placeholder=" "
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                required
              />
              <label htmlFor="surname" className="fs-14">
                {t('surname')}
              </label>
            </div>
            <div className="input-container">
              <input
                type="text"
                id="username"
                className="fs-14"
                placeholder=" "
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <label htmlFor="username" className="fs-14">
                {t('username')}
              </label>
            </div>
            <div className="input-container">
              <input
                type="text"
                id="email"
                className="fs-14"
                placeholder=" "
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <label htmlFor="email" className="fs-14">
                {t('email')}
              </label>
            </div>
            <div className="input-container">
              <input
                type="number"
                id="phone"
                className="fs-14"
                placeholder=" "
                value={phone}
                onChange={handlePhone}
              />
              <label htmlFor="phone" className="fs-14">
                {t('phone.number.text')}
              </label>
            </div>
            <div className="input-container">
              <input
                type="number"
                id="tckn"
                className="fs-14"
                placeholder=" "
                value={tckn}
                onChange={handleTckn}
              />
              <label htmlFor="tckn" className="fs-14">
                {t('tckn')}
              </label>
            </div>
            <div className="input-container">
              <input
                type="text"
                id="birthDay"
                className="fs-14"
                placeholder=" "
                value={birthDay ? birthDay.toISOString().substring(0, 10) : ''}
                onChange={handleBirthDay}
              />
              <label htmlFor="birthDay" className="fs-14">
                {t('birthDay')}
              </label>
            </div>
            <div className="input-container">
              <input
                type="password"
                id="password"
                placeholder=" "
                className="fs-14"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <label htmlFor="password" className="fs-14">
                {t('password')}:
              </label>
            </div>
            <button type="submit" className="btn btn-primary btn-block w-100">
              {t('login.register')}
            </button>
          </form>
          {error && <p className="text-danger text-center mt-3">{error}</p>}
          {success && (
            <p className="text-success text-center mt-3">{success}</p>
          )}
        </div>
      </div>
      <div className="container mt-5">
        <div className="row"></div>
      </div>
    </React.Fragment>
  )
}

export default Register
